import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { t } from '../../../domain/services/configService';
import { AnimatedButton } from '../../responsive/atoms/AnimatedButton';

const getTypeConfig = (type, canvasColor) => {
  const contrastColor = canvasColor === 'black' ? 'white' : 'black';
  const typeConfig = {
    primary: {
      bgColor: 'yellow',
      hoverBgColor: contrastColor,
      outlined: false
    },
    secondary: {
      bgColor: contrastColor,
      hoverBgColor: 'yellow',
      outlined: false
    },
    outlined: {
      bgColor: canvasColor,
      hoverBgColor: contrastColor,
      outlined: true
    }
  };
  return typeConfig[type];
};

const CallToActionButtonInner = ({
  url,
  text,
  openInNewTab,
  align,
  type,
  canvasColor
}) => {
  const target = openInNewTab === true ? '_blank' : null;
  const { bgColor, hoverBgColor, outlined } = getTypeConfig(type, canvasColor);
  const styles = {
    buttonWrapper: css`
      display: flex;
      justify-content: ${align};
      clear: both;
      margin-bottom: 20px;
    `
  };
  return (
    <div css={styles.buttonWrapper}>
      <AnimatedButton
        to={url}
        target={target}
        bgColor={bgColor}
        hoverBgColor={hoverBgColor}
        outlined={outlined}
      >
        {text || t('Read More')}
      </AnimatedButton>
    </div>
  );
};
CallToActionButtonInner.defaultProps = {
  text: undefined,
  openInNewTab: false,
  align: 'center',
  type: 'primary',
  canvasColor: 'white'
};
CallToActionButtonInner.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string,
  openInNewTab: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'start', 'center', 'right', 'end']),
  type: PropTypes.oneOf(['primary', 'secondary', 'outlined']),
  canvasColor: PropTypes.oneOf(['white', 'gray', 'black'])
};

export const CallToActionButton = ParagraphWrapper(CallToActionButtonInner);
